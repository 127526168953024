@font-face {
  font-family: "SourceSerif";
  src: url("/fonts/SourceSerif4-Italic-VariableFont_opsz,wght.ttf")
    format("truetype");
  font-style: italic;
  font-weight: 300 700;
}
@font-face {
  font-family: "SourceSerif";
  src: url("/fonts/SourceSerif4-VariableFont_opsz,wght.ttf") format("truetype");
  font-style: normal;
  font-weight: 300 900;
}
/* --------------------------- basic Elements ---------------------------- */
:root {
  --color-primary: red;
  --color-grey: white;
  --color-white: white;
  --color-black: black;
  --color-body: #141216;
  --color-articles: #fcfcfc;
  --Swidth: 99vw;
  --Sheight: 88vh;
  
}
body {
  font-family: "SourceSerif";
  background-color: lightgrey;
  overflow: hidden;
  margin: 0;
  padding: 0;
  width: auto;
  height: auto;


}
header{
  position: relative;
  top: 0px;
  height: auto;
  width: auto;
  background-color: white;
}
header a:hover{
  color: orange;
  cursor: pointer;
}
main {
  position: fixed;
  height: 95vh;
  display: grid;
  width: 100vw;
  grid-template-columns: 1fr;
  transition: all 400ms ease;
  overscroll-behavior: contain;
}
html {
  color: var(--color-body);
  
}
html {
  scroll-behavior: smooth;
}
ul,h1,figure{
  padding: 0px;
  margin: 0px 0px 0px 0px;
}
li{
  list-style: none;
}
a{
  text-decoration: none;
  color: inherit;
}
sup{
  line-height: 0px;
  color: orange;
  font-family: 'SourceSerif';
}
button {
  background-color: transparent;
  border: none;
  height: auto;
  width: auto;
  cursor: pointer;
}
/* --------------------------- endBasicElements ---------------------------- */
#menuSerie{
  --padding : 2vh;
  position: fixed;
  
  width: calc(100vw - 2 * var(--padding));
  height: calc(var(--Sheight) - 2* var(--padding));
  top: 5vh;
  z-index: 2000;
  background-color: var(--color-articles);
  padding: 2vh;
  visibility: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-top: solid 1px black;
  border-bottom: solid 1px black;
}
#menuSerie button{
  position: absolute;
  top: 10px;
  right: 10px;
 
}
#menuSerie button svg{

  height: 70%;
  width: 70%;

}
#menuSerie ul {
  /* display: grid; */
  column-count: 7; 
  column-gap: 15px;
  height: 100%;
  width: auto;
  align-self: center;
}
#menuSerie ul li {
  width: calc((var(--Swidth))/7 - 20px);
  cursor: pointer;
  /* border: solid 0.5px black; */
  break-inside: avoid;
  position: relative;
  
}
#menuSerie ul li img {
width: 100%;
height: auto;
object-fit: contain;
}
#menuSerie ul li a {
  position: absolute;
  z-index: 100;
  color: var(--color-articles);
  left: 10px;
  bottom: 10px;
  text-transform: uppercase;
  }
/*---------------------- Recherche --------------------------*/
#menuRecherche{
  position: fixed;
  width:100vw;
  height: 95vh;
  right: 0px;
  top: 5vh;
  z-index: 2000;
  background-color: var(--color-articles);
  visibility: hidden;
  display: none;
  
}
#menuRecherche article{
  width: auto;
  height: 85vh;
  overflow-y: scroll;
  overflow-x: hidden;
  padding: 5vh 5% 5vh 5%;
  display: flex;
  flex-direction: column;
  scroll-behavior: smooth;

}
#menuRecherche button{
  position: absolute;
  right: 10px;
  background-color: var(--color-articles);
  border-color: transparent;
  font-size: 20px;
 
}
#menuRecherche button svg{
  width: 70%;
  height: 70%;
}
#menuRecherche p {
  margin: 0px;
}
#menuRecherche h1 {
  font-size: 32px;
  font-weight: 400;
}
#listRe{
  background-color: var(--color-articles);
  height: 5vh;
  position:absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  border: 1px solid black;
  border-left: none;
  border-width: none;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

}
#listRe ul{
  display: flex;
  flex-direction: row;
  width: auto;
  height: 100%;
  align-items: center;
  justify-content: flex-start;
}
#listRe li{
  margin-left: 10px;

}
#listRe a{
  text-decoration: none;
  color: black;
}
#listRe a:hover{
  color: orange;
}
#listRe a:visited{
  color: initial;
}
#menuRecherche section {
  display: grid;
  grid-template-columns: 30% 55%;
  grid-gap: 4%;
  grid-template-rows: auto;
  scroll-margin-top: 20px;

}
#menuRecherche section h1 {
grid-column: 1/2;
grid-row: 1/2;
}
#menuRecherche section figure {
  object-fit: contain;

}
#menuRecherche section figure img{
max-width: 100%;
max-height: 100%;
}
#menuRecherche section figcaption{
  font-size: 11px;
}
.texteRecherche {
  font-family: 'SourceSerif',serif;

  line-height: 1.4em;
  padding: 12vh 4vw 10vh 10px;
}
.textR{
  grid-row: 1/2;
  grid-column: 2/3;

}
.infosR{
  grid-column: 1/2;
  grid-row: 1/2;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
#mapid {
  margin: 0;
  padding: 0;
  height: 100vh;
  /* width: 100vw; */
  background: #d4dadc;
  z-index: 0;
  visibility: hidden;
}
.reTitle{
  margin-top: 4vh;
  margin-bottom: 2vh;
}
#biblioContain{
  width: 90vw;
  grid-column: 1/3 ;
  display: grid;
  grid-template-columns: 30% 55%;
  grid-template-rows: 11vh auto;
  column-gap: 5vh;
  row-gap: 2vw;
}
#biblioContain h1 {

  grid-column: 1/3;
  grid-row: 1/2;
}
#imagesLivre {
  padding: 2vh 2vw;
  grid-column: 1/2;
  grid-row: 2/3;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  gap:4vh
  
}
#imagesLivre figure{
  
  max-width: 25vw;
  height: auto;
}
#listeLivre {
  padding: 2vw 0;
  grid-column: 2/3;
  grid-row: 2/3;
}
#listeLivre li{
  margin-top: 15px;
}
#listeLivre a:hover{
 color: orange;
}
.items {
  z-index: 1;
  background: var(--color-articles);
  /* padding: 2em; */
 height: 88vh;
  overflow-y: scroll;
  /* padding: 0 3em; */
 margin-bottom: 10px;
 
  /* box-shadow: 0 0 0 18px var(--color-white) inset,  0 0 0 20px var(--color-primary) inset; */
}
.items section:first-child{
  margin-top: 3vh;
}
.items .serie section{
  margin-top: 10vh;
}
/* example marker with icon */
.homeIcon svg circle:hover {
  fill: #FFFFFF;
}
.fullBlock {
  
  background: var(--color-primary);
  grid-template-columns: 1fr 0fr;
  animation: fBlock 500ms ease 20ms forwards;
  /* animation-fill-mode: forwards;   */
}
@keyframes fBlock {
  0% {
    grid-template-columns: 1fr 0fr;
  }

  33% {
    grid-template-columns: 1fr 1fr;
  }

  66% {
    grid-template-columns: 1fr 2fr;
  }

  

  100% {
    grid-template-columns: 1fr 3fr;
  }
}
.fullBlock #hideBlock {
  display: block;
}
#hideBlock {
  position: absolute;
  display: none;
  right: 30px;
  top: 20px;
  z-index: 4000;
  background: var(--color-white);
  width: 60px;
  height: 60px;
  border-radius: 50%;
  border: 1px solid black;
  box-shadow: none;
}
/* ----------------------------- landing -------------------------------------- */
#landing figure{
  height: 100vh;
  width: auto;
  position: relative;
  padding: 0px;
  margin: 0px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  
}
#landing{
 /* display: none !important; */
  padding: 0px;
  width: 100vw;
  height: 100vh;
  background-color: black;
  z-index: 1000;
  position: absolute;
  overflow-y: scroll;
  overflow-x: hidden;
  scroll-snap-type: y mandatory;
  scroll-behavior: smooth
}
#landing video{
  padding: 0px;
  width: auto;
  height: 100%;
  z-index: 1100;
}
.container-land{
  height: 100vh;
  width: 100vw;
  scroll-snap-align: center;
  position: relative;
}
#landingvid{
  width: 100%;
}
#vid{
  height: 100vh;
  width: auto;
  position: relative;
}
#caption{
  position: absolute;
  color: var(--color-articles);
  z-index: 2000;
  top: 30vh;
  width: 60vw;
left: 20vw;
text-align: center;
font-size: 1.2em;
font-weight: 400;
}
#caption h1{
  font-weight: 400;
  font-size: 1.8em;
}
#landIntro{
  --width: 55vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  width: var(--width);
  left: calc((100vw - var(--width))/2);
  height: auto;
  top: 30vh;
  color: white;
  text-align: center;
}
#landIntro h1{
  font-size: 2em;
}
#landIntro h2{
  font-size: 1.2em;
}
.hideland{
  animation: hideLand 900ms forwards;
}
#detector{
  position: absolute;
  bottom: 0px;
  height: 20px;
  width: 20px;
}
.hidden{
  visibility: hidden;
}
@keyframes hideLand {
  from {
    opacity: 1;
  }
  to{
    opacity: 0;
  }
  
}
/* ----------------------------- endLanding -------------------------------------- */
/* ------------------- notes ------------------------- */
#notesContain{
  grid-column: 2/3;
  display: flex;
  flex-direction: column;
  justify-content: start;
  padding: 0px 4vw 0px 10px
}
#notes div{
  margin: 1vh 0px 1vh 0px;
  font-size: 12px;

}
#notes span{
  color: orange;
  
}
#notesPreview{

  position: fixed;
  bottom: 0px;
  right: 15px;
  width: 14vw;
  height: auto;
  max-height: 83vh;
  background-color: var(--color-articles);
  font-size: 12px;
  padding: 10px 20px 25px 20px;
  overflow-y: scroll;
  /* border-top: solid 1px lightgrey; */

}
#notesPreview > div{
display: none;
margin-top: 10px;


}
#notesPreview > div span{
  color: orange;
  
  }
/* ------------------- endNotes ------------------------- */
/* .leaflet-marker-icon.new-york { */
/* color: indianred !important; */
/* display: none; */
/* } */
.hide {
  display: none !important;
}
.leaflet-marker-icon {
  opacity: 0.9;
}
.leaflet-marker-icon.active {
  color: var(--color-primary);
}
.active {
  color: var(--color-primary);
  opacity: 1;
}
/* .markerSection {
  /* display: none; */
/* } */
/* .markerSection.show { */
/* display: block; */
/* } */
.serie.show {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  
}
.serieNav{
  width: 96%;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  position: sticky;
  top: -1px;
  background-color: var(--color-articles);
  align-items: center;
  padding: 3% 0px 3% 4%;
  z-index: 40;
}
.serieNav ul {
  display: flex;
  flex-direction: row;
  width: 60%;
  justify-content: flex-start;
  margin-top: 7px;
  font-size: 0.9em;
 
  
}
.serieNav ul li {
  margin-right: 10px;
  
}
.serieNav ul li:hover{
  color: orange;
}
.serie > h1:nth-child(1) {
  padding: 0 5% ;
  width: 90%;
}
.serie > *:not(*:nth-child(1)){
  padding: 0 0em;
}
.serie {
  display: none;
  min-height: 200vh;
  scroll-behavior: smooth;

}
.serie > nav > h1 {
  /* background: var(--color-white); */
  font-size: 3em;
  position: sticky;
  top: 0px;
  padding-top: 0px;
  padding-bottom: 0px;
  background-color: var(--color-articles);
  margin-right: 15px;
  line-height: 1.5em;
}
.serie > h1 span {
  text-transform: uppercase;
  font-size: 0.2em;
  letter-spacing: 0.5ch;
  font-family: sans-serif;
  font-style: normal;
  font-weight: 300;
}
.serie.show h1 {
  opacity: 1;
}
.menu {
  position: fixed;
  top: 10vh;
  left: 10vw;
  background: rgb(0, 0, 0, 0.1);
  padding: 1em;
  min-width: 30ch;
  display: none;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 3em;
}
.menu.show {
  display: grid;
}
.menu input {
  position: absolute;
  left: -300000px;
}
.menu :checked + label {
  text-decoration: underline;
  font-weight: 800;
  color: black;
}
#showTags {
  position: absolute;
  right: 30px;
  top: 80px;
  z-index: 4000;
  background: var(--color-white);
  width: 60px;
  height: 60px;
  border-radius: 50%;
  border: 1px solid black;
  box-shadow: none;
}
.serie > header h1 {
  font-size: 3em;
  font-style: italic;
}
.serie > header #serie-intro {
  font-size: 1.3em;
}
#serie-intro {
  max-width: 100%; 
  padding: 0 3em;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 2vw;
  
}
#serie-intro h1 {
  font-size: 2em;

}
#serie-intro p {
  font-family: var(--font-sans);
  margin-bottom: 3em;
  hyphens: auto;
  grid-column: 1/2;
  border-left: solid 1px black;
  padding-left: 15px;
}
#serie-intro figure{
  width: 100%;
  justify-self: center;
  align-self: top;
  grid-row: 1/2;
  grid-column: 2/3;
}
#serie-intro img{
  width: 100%;
}
.ariane {
  position: fixed;
  bottom: 0;
  /* width: 100vw; */
  /* overflow-x: auto; */
  height: 7vh;
  width: 100%;
  /* text-transform: uppercase;*/
  /* font-family: sans-serif; */
  border-top: 1px solid black;
  background: var(--color-grey);
  margin: 0;
  z-index: 10;
}
.ariane img {
  height: 5.5vh;
  
  width: auto;
  object-fit: contain;
}
.ariane ul {
  display: flex;
  flex-direction: row;
  gap: 1vh;
  margin: 0.5vh 1vh;
  z-index: 49999;
  list-style-type: none;
  height: 6vh;
  align-items: center;
}
.ariane ul li {
  height: 5.5vh;
}
.ariane img:hover {
  
  filter: grayscale(100%);
}
article {
  overflow-y: auto;
}
article.show {
  display: none;
  position: fixed;
  right: 0;
  top: 0;
  bottom: 0;
  width: 90ch;
  padding: 2em;
  background: var(--color-white);
  border-left: 2ch solid var(--color-black);
}
.indianred .showSomething + article.show {
  display: block;
}
.icons {
  display: none;
}
figure .meta li {
  list-style-type: none;
  margin-bottom: 2em;
}
.meta svg {
  width: 30px;
  height: auto;
}
figure .meta {
  padding: 0;
  margin: 0;
}
figure .meta button:hover {
  color: var(--color-primary);
}
.markerSection article {
  display: none;
}
.markerSection article.show {
  display: block;
}
.closefiche {
  position: absolute;
  left: 2px;
  background: none;
  border: none;
  position: sticky;
  top: 2px;
  width: 50px;
}
.closefiche:hover {
  color: va(--color-primary);
}
.meta-content {
  list-style-type: none;
}
.meta-content li {
  margin-bottom: 3em;
}
.meta-content svg {
  width: 30px;
  position: relative;
}
.caption {
  align-items: end;
}
.items.lock {
  overflow: hidden;
}
#mapid {
  --zoom-start: 20px;
  --zoom-level: 2;
  --zoom-ratio: 1;

}
#mapid img[src*="/images/"] {
  width: calc(var(--zoom-start) * (var(--zoom-level) * var(--zoom-ratio)));
  height: auto;
  border: 2ch solid white;
  box-shadow: 0 0 0 0.4ch black;
  transition: all 400ms;
}
#mapid img[src*="/images/"]:hover {
  z-index: 400000 !important;
  position: absolute;
}
.hideMap {
  grid-template-columns: 0 4fr 4fr;
}
#navMenu{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 5vh;
  width: 100vw;
  border-bottom: 1px solid black;
  z-index: 10;
}
#navMenu * {
  margin: 0 10px 0 10px;
  align-self: center;
}
/* -------------------- serie ------------------ */
.serieAnchor{
  cursor: pointer;
}
#serie-photo{

  display: grid;
  grid-template-columns: repeat(3,minmax(0,1fr));
  grid-auto-rows: minmax(40vh, auto);
  grid-row-gap: 19vh;
  width: auto;
  overflow: hidden;
  
  /* min-height: 100vh; */
}
#serie-photo figure{
  height: auto;
}
#serie-photo figure figcaption p{ 
  font-size: 0.75em;
  margin: 0px 0 0 0;
  padding: 0px 7px 0px 3px;
  font-style: italic;
}
#serie-photo img{
width: 100%;
height: auto;
}
.serie-photo-citation{
  padding: 20px;
  width: 130%;
  justify-self: center;
  overflow: visible;
  font-weight: 500;
  align-self: center;

  
}
.serie-photo-citation h1{
font-size: 1.5em;
font-weight: 500;
}
#serie-photo .gauche {

  justify-self: left;
}
#serie-photo .centre{

  justify-self: center;
}
#serie-photo .droite{

  justify-self: right;
}
#serie-photo .petite.landscape {

  width: 70%;
  
}
#serie-photo .petite.portrait {

  width: 75%;
  
}
#serie-photo .moyenne.landscape{

  width: 130%;

  
}
#serie-photo .moyenne.portrait {

  width: 100%;
  
}
#serie-photo .grande.portrait {

  width: 140%;
}
#serie-photo .grande.landscape {

  width: 195%;
}
#serie-photo .pleinePage figcaption p {
  text-align: center;
}
.pleinePage{width: 75vw;
margin-top: 10%;
grid-row: -1}
.haut{
  align-self:flex-start;
}
.bas{
  align-self:flex-end;
}
.milieu{
  align-self:center;
}
#serie-texte{
  margin-top: 15%;
  padding: 0 10%;
}
#serie-texte h1{

  font-size: 2em;
}
#serie-texte h2{

  font-size: 1em;
}
#serie-acteurices{
  background-color: rgb(245, 231, 190, 1);
  width: auto;
  margin-top: 0px;
}
#serie-acteurices h1{
  font-size: 2em;
  padding: 3vw 0px 0px 3vw;
}
#acteuricesContainer{
  column-count: 4;
  column-gap: 25px;
  padding: 0 3vw;
  margin: 4vh 0;
  
}
#acteuricesContainer :empty{
  background-color: #fcfcfc;
  
}
#acteuricesContainer figure {
  margin: 0;
  display: grid;
  grid-template-rows: 1fr auto;
  margin-bottom: 50px;
  break-inside: avoid;
}
#acteuricesContainer figure > img {
  grid-row: 1 / -1;
  grid-column: 1;
}
#acteuricesContainer img {
  max-width: 100%;
  display: block;
}
#acteuricesContainer figcaption {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 5px;
  
}
#acteuricesContainer figcaption p{
  margin: 0 0 0 0;
  font-family: sans-serif;
}
#acteuricesContainer figcaption>p{
  margin-top: 10px;
}
.ActeuricesRole{
  height: auto;
  font-size: 70%;
  color: black;
  
}
.ActeuricesRole a:hover{
cursor: pointer;
color: orange;
}
#serie-coulisse{

  display: grid;
  grid-template-columns: repeat(3,minmax(0,1fr));
  grid-auto-rows: minmax(40vh, auto);
  grid-row-gap: 19vh;
  width: auto;
  overflow: hidden;
  background-color: rgb(255, 249, 230);
  padding: 13vh 3vw 10vh 3vw;
  z-index: 1;
  position: relative;
  /* min-height: 100vh; */
}
#serie-coulisse figure{
  height: auto;
}
#serie-coulisse figure figcaption p{ 
  font-size: 0.75em;
  margin: 0px 0 0 0;
  padding: 0px 7px 0px 3px;
  font-style: italic;
}
#serie-coulisse img{
width: 100%;
height: auto;
/* filter: url(#duotone) contrast(105%); */
filter: grayscale(1);
mix-blend-mode: multiply;
cursor: pointer;
transition: 0.2s ease;
}
#serie-coulisse h1{

  position: absolute;
  top: 3vw;
  left: 3vw;
  font-size: 2em;

}
#serie-coulisse img:hover{
  mix-blend-mode: normal;
  filter: grayscale(0);
  }
#serie-coulisse .gauche {

  justify-self: left;
}
#serie-coulisse .centre{

  justify-self: center;
}
#serie-coulisse .droite{

  justify-self: right;
}
#serie-coulisse .petite.landscape {

  width: 70%;
  
}
#serie-coulisse .petite.portrait {

  width: 75%;
  
}
#serie-coulisse .moyenne.landscape{

  width: 130%;

  
}
#serie-coulisse .moyenne.portrait {

  width: 100%;
  
}
#serie-coulisse .grande.portrait {

  width: 140%;
}
#serie-coulisse .grande.landscape {

  width: 195%;
}
#serie-coulisse .pleinePage figcaption p {
  text-align: center;
}
/* --------------------------------------------- */
/* ---------Preview Container for fiches et image-------- */
#containerCloseBtn{
  position: absolute;
  right: 1vw;
  top: 3.3vh;
}
#containerCloseBtn svg{
  width: 70%;
  height: 70%;
}
#previewContainer{
  height: 87.7vh;
  width: 75vw;
  background-color: var(--color-articles);
  z-index: 1000;
  position: fixed;
  top: 5.5vh;
  right: 0px;
  overflow: scroll;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none;  /* IE 10+ */
}
#previewContainer::-webkit-scrollbar {
  background: transparent; /* Chrome/Safari/Webkit */
  width: 0px;
}
.ficheContainer{
  width: 96%;
  height: 95%;
  
  display: grid;
  grid-template-columns: 35% 65%;
  grid-template-rows: auto;
  grid-gap: 25px;
  padding:2%;
  overflow-x: hidden;
  

}
.ficheContainer figure{
  width: 100%;
  
  height: fit-content;

}
.ficheContainerLeft{
  grid-column: 1;
  grid-row: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.ficheContainer figure img{
  width: 100%;
}
.ficheContainer .ficheTags{
  width: 90%;
  padding: 10px;
}
.ficheContainer .ficheInfos{
  display: flex;
  flex-direction: column;
  grid-column:2;
}
.ficheInfos > * {
  margin-bottom: 20px;
}
.ficheInfos h1 {
  width: 80%;
  font-weight: 400;
}
.ficheInfos .ficheName {
display: flex;
flex-direction: column;
height: auto;
gap: 3px;
margin-bottom: 3px;

}
.ficheInfos .ficheName * {
  margin-right: 10px;
  
  }
.ficheInfos .ficheData {
  display: flex;
  flex-direction: row;
  }
.ficheInfos .ficheData *{
  margin-right: 10px;
}
.ficheName p{
margin: 0px;
padding: 0px;
}
.fiche{
  cursor: pointer;
}
.ficheTexte {
  width: 85%;
  font-size: 0.9em;
}
.leaflet-tile-pane{
  filter: grayscale(100%) contrast(130%) saturate(0.5)hue-rotate(100deg) brightness(0.5);
}
